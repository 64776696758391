@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {

  .step-header {
    @apply mb-10 md:mb-16 flex flex-col items-start sm:flex-row sm:items-center text-gray-medium text-base leading-5 font-light;
  }

  .step-header-box {
    @apply shrink-0 mr-4 mb-2 sm:mb-0 py-1 px-2 rounded-lg bg-blue-main text-white;
  }

  .section-container {
    @apply mb-8 md:mb-12;
  }

  .section-header {
    @apply mb-5;
  }

  .section-title {
    @apply text-lg md:text-xl leading-6 text-gray-main font-semibold mb-1;
  }

  .section-subtitle {
    @apply text-sm md:text-base text-gray-medium-light font-light;
  }

  .input {
    @apply w-full max-w-[500px] bg-white text-gray-medium border-2 border-gray-light p-2 rounded-lg resize-none placeholder:text-gray-light focus:outline-none focus:border-orange-main;
  }

  .input.small {
    @apply text-sm p-1 border;
  }

  .input.complete {
    @apply bg-green-xlight border-none cursor-pointer truncate whitespace-pre-line;
  }

  .input-icon {
    @apply fill-orange-main;
  }

  .input-icon.complete {
    @apply fill-green-light cursor-pointer;
  }

  .input-box {
    @apply input max-w-[800px] p-3;
  }

  .select {
    @apply input relative max-w-[300px] cursor-pointer appearance-none bg-arrow-down bg-no-repeat bg-right;
  }

  .button {
    @apply relative text-lg py-2 px-8 rounded-md transition-transform hover:opacity-75;
  }

  .button.small {
    @apply text-sm py-0.5 px-2 rounded-md hover:opacity-75;
  }

  .button.orange {
    @apply bg-orange-main text-white;
  }

  .button.green {
    @apply bg-green-light text-green-main;
  }

  .button.sending {
    @apply text-transparent;
  }

  .button.sending::after {
    position: absolute;
    top: calc(50% - 12.5px);
    left: calc(50% - 12.5px);
    height: 25px;
    width: 25px;
    border: 3px solid white;
    border-right-color: transparent;
    border-radius: 50%;
    box-sizing: border-box;
    content: "";
    animation: spin .75s linear infinite;
  }
  
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
}